import React, { createContext, useState } from "react";

export const UmbracoContext = createContext();

export const UmbracoProvider = ({ children }) => {
    const [data, setData] = useState(null);

    let fakeData = {
        id: 1060,
        key: "dc51acd3-1737-48f1-8af4-d73223b0926f",
        title: "Matthew",
        slug: "matthew",
        url: "/ansatte/matthew",
        type: "employee",
        firstName: "Matthew",
        lastName: "Hessaa-Szwinto",
        jobTitle: "dev",
        phone: "132456",
        mobile: "65498",
        email: "matthew.szwinto@07.no",
        streetAddress: "",
        city: "",
        zip: "",
        image: {
            id: 1059,
            key: "16bfe7fe-0156-4d47-9d82-807226d8c31b",
            title: "Sanitærprodukter Servicepartner1",
            slug: null,
            url: "https://picsum.photos/200/300",
            type: "image",
            umbracoFile: "",
            umbracoWidth: "3504",
            umbracoHeight: "2336",
            umbracoBytes: "1794955",
            umbracoExtension: "jpg",
        },
        homePage: "",
    };

    return (
        <UmbracoContext.Provider value={{ data, setData }}>
            {children}
        </UmbracoContext.Provider>
    );
};
