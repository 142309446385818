import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Stylesheet
import "sass/main.scss";

// Contexts
import { UmbracoProvider } from "contexts/Umbraco";

let root = document.getElementById("root");
let nodeId = parseInt(root?.dataset?.nodeId);
let rootId = parseInt(root?.dataset?.rootId);
ReactDOM.render(
    <React.StrictMode>
        <UmbracoProvider>
            <App nodeId={nodeId} rootId={rootId} />
        </UmbracoProvider>
    </React.StrictMode>,
    root
);
