import React, { useContext } from "react";

// Internal components
import Logo from "components/Logo";

// External components
import QRCode from "qrcode.react";
import { BsPhone, BsEnvelope } from "react-icons/bs";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const BusinessCard = () => {
    const { data } = useContext(UmbracoContext);
    console.log(data)
    return (
        <div className="business-card-wrapper">
            <div className="business-card">
                {/* <Logo className="business-card_logo" /> */}
                {/* Changed to dynamic image from api */}
                <div className="business-card_logo">
                    <img className="logo" src={data?.root?.logo?.url} />
                </div>
                <div className="business-card_content">
                    {data && (
                        <>
                            <div className="business-card_content_qr">
                                {data.vCard && (
                                    <QRCode
                                        value={data.vCard}
                                        size={200}
                                        bgColor="transparent"
                                        fgColor="#315877"
                                    />
                                )}
                            </div>
                            <div>
                                <hr />
                                {data.image?.url && <img
                                    src={data?.image?.url}
                                    className="business-card_content_image"
                                    alt={data.firstName || "profile"}
                                />}
                                <h1>
                                    {data.firstName} {data.lastName}
                                </h1>
                                <div className="business-card_content_data">
                                    {data.jobTitle && (
                                        <p>
                                            <em>{data.jobTitle}</em>
                                        </p>
                                    )}
                                    <hr />
                                    <ul>
                                        <li>
                                            <BsEnvelope size="1.5rem" />
                                            <span>{data.email}</span>
                                        </li>
                                        <li>
                                            <BsPhone size="1.5rem" />
                                            <span>{data.mobile}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessCard;
