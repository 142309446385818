import React, { useEffect, useContext } from "react";

// Internal components
import BusinessCard from "components/BusinessCard";

// Contexts
import { UmbracoContext } from "contexts/Umbraco";

const App = ({ nodeId, rootId }) => {
    const { setData } = useContext(UmbracoContext);
    useEffect(() => {
        const getData = async () => {
            const rootRequest = await fetch(`/headless/data/${rootId}`);
            const root = await rootRequest.json();
            const employeeRequest = await fetch(`/headless/data/${nodeId}`);
            const employee = await employeeRequest.json();
            const vCardRequest = await fetch(`/headless/vcard/${nodeId}`);
            const vCard = await vCardRequest.text();
            setData({
                ...employee,
                vCard,
                root
            });
        };
        getData().catch(e => console.log(e));
    }, [nodeId, setData]);

    return (
        <BusinessCard />
    );
};

export default App;
